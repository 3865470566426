import React from "react"
import Layout from "../layouts/en"
import ContactPageBase from "../templates/contact"

export default () => {
    return (
        <Layout>
            <ContactPageBase locale="en" />
        </Layout>
    )
}
